import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';
import { PermissionPipe } from './pipe/permission.pipe';
import { GlobalErrorHandleService } from './services/global-error-handle.service';
import { DirectiveModuleModule } from './directives/directive-module/directive-module.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleCasePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    PermissionPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 4000,
      preventDuplicates: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true,
    }),
    DirectiveModuleModule.forRoot(),
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandleService },
    TitleCasePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
